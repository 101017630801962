import { Prisma } from "@prisma/client";
import SuperJSON from "superjson";

SuperJSON.registerCustom<Prisma.Decimal, string>(
  {
    isApplicable: (v): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Prisma.Decimal(v),
  },
  "decimal.js",
);

export default SuperJSON;
export * from "superjson";
