"use client";

import { Anchor, Container, Group, Stack, Text } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import kiwiTicketLogo from "public/assets/images/kiwiticket_logo.svg";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <Container size="sm">
        <Stack align="center" justify={"stretch"}>
          <Group w="100%" align="start" justify="space-between">
            <Stack gap={2} align="start">
              <Text>KiwiTicket</Text>
              <Link href="/" passHref legacyBehavior>
                <Anchor size="sm">Home</Anchor>
              </Link>
              <Link href="/about" passHref legacyBehavior>
                <Anchor size="sm">About</Anchor>
              </Link>
              <Link href="/faq" passHref legacyBehavior>
                <Anchor size="sm">FAQ</Anchor>
              </Link>
              <Link href="/articles" passHref legacyBehavior>
                <Anchor size="sm">Help Articles</Anchor>
              </Link>
            </Stack>

            <Stack gap={2} align="start">
              <Text>Privacy</Text>
              <Link href="/privacy" passHref legacyBehavior>
                <Anchor size="sm">Privacy Notice</Anchor>
              </Link>
              <Link href="/terms-and-conditions" passHref legacyBehavior>
                <Anchor size="sm">Terms and Conditions</Anchor>
              </Link>
            </Stack>

            <Stack gap={2} align="start">
              <Text>Sell Tickets</Text>
              <Link href="/sell" passHref legacyBehavior>
                <Anchor size="sm">Sales Information</Anchor>
              </Link>
              <Link href="/fees" passHref legacyBehavior>
                <Anchor size="sm">Fees</Anchor>
              </Link>
              <Anchor size="sm" href="mailto:help@kiwiticket.co.nz">
                Contact
              </Anchor>
            </Stack>
          </Group>

          <Group>
            {`© ${new Date().getFullYear()}`}
            <Image src={kiwiTicketLogo} alt="KiwiTicket Logo" width={24} height={24} />
            KiwiTicket
          </Group>
        </Stack>
      </Container>
    </footer>
  );
};

export default Footer;
