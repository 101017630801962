import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/app/(customer)/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/app/(customer)/components/MobileBottomNavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["closeAllModals","closeModal","modals","openConfirmModal","openContextModal","openModal","updateContextModal","updateModal"] */ "/vercel/path0/node_modules/.pnpm/@mantine+modals@7.15.0_@mantine+core@7.15.0_@mantine+hooks@7.15.0_react@19.0.0__@types+react@_mjnsvygypz2cvkogfkra5i6ahq/node_modules/@mantine/modals/esm/events.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ModalsProvider"] */ "/vercel/path0/node_modules/.pnpm/@mantine+modals@7.15.0_@mantine+core@7.15.0_@mantine+hooks@7.15.0_react@19.0.0__@types+react@_mjnsvygypz2cvkogfkra5i6ahq/node_modules/@mantine/modals/esm/ModalsProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useModals"] */ "/vercel/path0/node_modules/.pnpm/@mantine+modals@7.15.0_@mantine+core@7.15.0_@mantine+hooks@7.15.0_react@19.0.0__@types+react@_mjnsvygypz2cvkogfkra5i6ahq/node_modules/@mantine/modals/esm/use-modals/use-modals.mjs");
