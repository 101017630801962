"use client";

import { Box, Button, Group, Indicator, IndicatorProps, Text } from "@mantine/core";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { translate } from "react-i18nify";
import { api } from "~/trpc/react";
import classes from "./MobileBottomNavBar.module.css";
import { IconCalendarEvent, IconShoppingCart, IconTicket } from "@tabler/icons-react";
import { useUser } from "reactfire";

type HeaderLink = {
  icon?: ReactNode;
  link: string;
  label: string;
  testid?: string;
  className?: string;
  indicatorProps?: Partial<IndicatorProps>;
};

const MobileBottomNavBar = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { data: user } = useUser();
  const { data: cartItems, refetch } = api.cart.items.useQuery(undefined, {
    enabled: Boolean(user),
  });
  // biome-ignore lint/correctness/useExhaustiveDependencies: refetch on user change
  useEffect(() => {
    refetch();
  }, [user?.uid]);

  const wrapWithIndicator = (link: HeaderLink, item: ReactNode) => {
    if (link.indicatorProps) {
      return (
        <Indicator
          {...link.indicatorProps}
          className={classes.indicator}
          data-testid={`${link.testid}-indicator`}
        >
          {item}
        </Indicator>
      );
    }
    return item;
  };

  const items: HeaderLink[] = [
    {
      label: translate("header.events"),
      link: "/events",
      icon: <IconCalendarEvent />,
      testid: "events",
    },
    {
      label: translate("header.cart"),
      icon: <IconShoppingCart />,
      link: "/cart",
      testid: "cart",
      indicatorProps:
        cartItems && cartItems.length > 0
          ? {
              color: "red",
              label: cartItems.reduce((total, ticket) => total + ticket.quantity, 0),
              size: 20,
              offset: 22,
              position: "middle-end",
            }
          : undefined,
    },
    {
      label: translate("header.tickets"),
      icon: <IconTicket />,
      link: "/tickets",
      testid: "tickets",
    },
  ];

  const navItems = items.map((item, index) => {
    const isActive =
      pathname?.startsWith(item.link) ||
      (searchParams?.get("redirect") as string)?.startsWith(item.link) ||
      (index === 0 && pathname === "/");

    return (
      <Link key={`nav-item-${item.testid}`} data-testid={item.testid} href={item.link}>
        {wrapWithIndicator(
          item,
          <Button
            id={`bottom-nav--${item.label}`}
            data-testid={`bottom-nav--${item.label}`}
            radius="xl"
            variant={isActive ? "light" : "subtle"}
          >
            <Group gap={4} pr={item.indicatorProps ? 24 : 0}>
              {item.icon}

              {isActive && <Text size="sm">{item.label}</Text>}
            </Group>
          </Button>,
        )}
      </Link>
    );
  });

  return (
    <Box className={classes.container}>
      <Group wrap="nowrap">{navItems}</Group>
    </Box>
  );
};

export default MobileBottomNavBar;
