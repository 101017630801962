"use client";

import classNames from "classnames";
import Image from "next/image";
import kiwiTicketLogo from "public/assets/images/kiwiticket_logo.svg";
import React from "react";

type Props = {
  className?: string;
  size?: number;
};

export const KiwiTicketLogo = ({ size = 96, className }: Props) => (
  <Image
    alt="KiwiTicket logo"
    src={kiwiTicketLogo}
    width={size}
    height={size}
    className={classNames(className, "kiwiticket-logo")}
  />
);
